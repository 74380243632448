import { BookmarkFilledIcon, BookmarkIcon, HeartFilledIcon, HeartIcon } from "@radix-ui/react-icons";
import { useMemo, useState } from "react";
import { Button, Flex, Text } from "sparta";
import { useRootLoaderData } from "~/root";
import { InteractionType, useInteractionsV2 } from "~/utils/hooks/useInteractionsV2";

type IInteractions = {
  postId: string;
  initialLikes: string[];
  initialBookmarks: string[];
  onInteractionClick: (event: React.MouseEvent) => void;
  setShowLoginModal: (event: React.MouseEvent) => void;
};

export function Interactions({
  postId,
  initialLikes,
  initialBookmarks,
  onInteractionClick,
  setShowLoginModal,
}: IInteractions) {
  const { user, token } = useRootLoaderData();
  const userLoggedIn = !!user && !user.anonymous;
  const [likes, setLikes] = useState(initialLikes);
  const [bookmarks, setBookmarks] = useState(initialBookmarks);
  const [animating, setAnimating] = useState(false);
  const [Bookmarkanimating, setBookmarkAnimating] = useState(false);
  const [showAllModal, setShowAllModal] = useState(false);
  const likesCount = useMemo(() => likes?.length, [likes]);
  const liked = useMemo(() => (user?.id ? likes?.includes(user.id) : false), [likes, user]);
  const bookmarked = useMemo(() => (user?.id ? bookmarks?.includes(user.id) : false), [bookmarks, user]);

  const { handleInteraction } = useInteractionsV2(postId, token);

  const handleLike = async (event: React.MouseEvent) => {
    if (!userLoggedIn) {
      setShowLoginModal(event);
      return;
    }

    if (!liked) {
      setAnimating(true);
    }
    const usersLiked = await handleInteraction(InteractionType.LIKE);
    setLikes(usersLiked);
  };

  const handleLikeClick = (event: React.MouseEvent) => {
    onInteractionClick(event);
    handleLike(event);
  };

  const animationEnd = () => {
    setAnimating(false);
  };

  const bookmarkAnimationEnd = () => {
    setAnimating(false);
  };

  const handleBookmark = async (event: React.MouseEvent) => {
    if (!userLoggedIn) {
      setShowLoginModal(event);
      return;
    }

    if (!bookmarked) {
      setBookmarkAnimating(true);
    }
    const usersBookmarked = await handleInteraction(InteractionType.BOOKMARK);
    setBookmarks(usersBookmarked);
  };

  const handleBookmarkClick = (event: React.MouseEvent) => {
    onInteractionClick(event);
    handleBookmark(event);
  };

  return (
    <>
      <Flex width={"100%"} id="interactions-section">
        <Flex width={"100%"} align={"center"} justify={"end"}>
          <Flex mr={"5px"} gap="12px" align={"center"} justify={"end"}>
            <Flex gap="2" align="center">
              {likesCount > 0 ? (
                <>
                  <HeartFilledIcon color="var(--red-9)" />
                  <Text>{likesCount}</Text>
                </>
              ) : null}
            </Flex>
            <Flex align={"center"} position={"relative"} height={"20px"} gap="12px">
              <Button
                color="teal"
                leftIcon={
                  liked ? (
                    <HeartFilledIcon className={animating ? "animated" : ""} onAnimationEnd={animationEnd} />
                  ) : (
                    <HeartIcon className={animating ? "animated" : ""} onAnimationEnd={animationEnd} />
                  )
                }
                variant="ghost"
                onClick={handleLikeClick}
              >
                {liked ? "Unlike" : "Like"}
              </Button>
              <Button
                color="teal"
                leftIcon={
                  bookmarked ? (
                    <BookmarkFilledIcon
                      className={Bookmarkanimating ? "bookmark-animated" : ""}
                      onAnimationEnd={bookmarkAnimationEnd}
                    />
                  ) : (
                    <BookmarkIcon
                      className={Bookmarkanimating ? "bookmark-animated" : ""}
                      onAnimationEnd={bookmarkAnimationEnd}
                    />
                  )
                }
                variant="ghost"
                onClick={handleBookmarkClick}
              >
                Bookmark
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
