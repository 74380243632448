import { Button, Flex, Image } from "sparta";
import fallback from "~/assets/fallback.png";

interface IThumbnail {
  thumbnail?: {
    url: string;
  };
  image?: string;
  link?: string;
  className?: string;
}

export default function Thumbnail({ thumbnail, link, className, image }: IThumbnail) {
  return (
    <Flex flexGrow="0" style={{ borderRadius: 4 }}>
      <Image
        src={(thumbnail?.url ?? image)?.length ? thumbnail?.url ?? image : fallback}
        alt="post-image"
        className={`post-image ${className}`}
        loading="lazy"
        borderRadius="4px"
      >
        {link ? (
          <div className="post-image-overlay">
            <a href={link}>
              <Button size="1">Read More</Button>
            </a>
          </div>
        ) : null}
      </Image>
    </Flex>
  );
}
